







































import { Vue, Component, Prop } from 'vue-property-decorator';
import type { InfoListItem as InfoListItemType } from '@/lib/types/InfoList.type';

@Component({
  name: 'InfoListItem',
})
export default class InfoListItem extends Vue {
  @Prop({ required: false, default: false })
  protected readonly isSibling!: boolean;

  @Prop({ required: true, default: () => ({}) })
  private readonly item!: InfoListItemType;

  /**
   * Get class list for item
   */
  protected classList() {
    return (Array.isArray(this.item.class)) ? this.item.class.join(' ') : this.item.class ?? '';
  }
}
